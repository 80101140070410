<template>
  <svg
      fill="none"
      height="0"
      style="position: absolute"
      width="0"
      xmlns="http://www.w3.org/2000/svg"
  >
    <!--    <symbol id="i_arrow" viewBox="0 0 24 24">-->
    <!--      <path-->
    <!--          d="M6.77649 5.16325L13.5983 12L6.77649 18.8367L8.87667 20.9369L17.8136 12L8.87666 3.06308L6.77649 5.16325Z"-->
    <!--      />-->
    <!--    </symbol>-->
    <!--    <symbol-->
    <!--        id="i_inventory"-->
    <!--        viewBox="0 0 22 22"-->
    <!--        xmlns="http://www.w3.org/2000/svg"-->
    <!--    >-->
    <!--      <path-->
    <!--          clip-rule="evenodd"-->
    <!--          d="M20 5V2H2v3h18zM2 7v8h3V7H2zm5 0v8h8V7H7zm10 0h3v8h-3V7zm3 10H2v3h18v-3zM2 0a2 2 0 00-2 2v18a2 2 0 002 2h18a2 2 0 002-2V2a2 2 0 00-2-2H2z"-->
    <!--          fill="#58E2B0"-->
    <!--          fill-rule="evenodd"-->
    <!--      ></path>-->
    <!--    </symbol>-->
    <symbol
        id="i_round"
        fill="none"
        viewBox="0 0 16 16"
    >
      <path
          d="M16 8A8 8 0 110 8a8 8 0 0116 0z"
          fill="url(#paint0_radial)"
          opacity=".4"
      ></path>
      <path d="M12 8a4 4 0 11-8 0 4 4 0 018 0z" fill="#58E2B0"></path>
      <defs>
        <radialGradient
            id="paint0_radial"
            cx="0"
            cy="0"
            gradientTransform="matrix(0 8 -8 0 8 8)"
            gradientUnits="userSpaceOnUse"
            r="1"
        >
          <stop offset=".453" stop-color="#58E2B0" stop-opacity="0"></stop>
          <stop offset="1" stop-color="#58E2B0" stop-opacity=".25"></stop>
        </radialGradient>
      </defs>
    </symbol>
    <symbol
        id="loader"
    >
      <path
          d="M3.75552 7.73066L2.92651 9.54984C3.5534 9.83552 4.23197 9.9883 4.91955 9.99935C4.97721 10.0003 5.03493 10.0002 5.09269 9.99914C5.83924 9.98528 6.57321 9.8044 7.24071 9.46977C7.9082 9.13513 8.49227 8.65525 8.95004 8.06536C9.40781 7.47546 9.72765 6.79053 9.88607 6.06085C10.0445 5.33117 10.0375 4.57527 9.86554 3.84866C9.6936 3.12205 9.36111 2.44317 8.89248 1.86187C8.42384 1.28057 7.83096 0.811607 7.15737 0.48942C7.10525 0.464492 7.05276 0.440491 6.9999 0.417424C6.36965 0.142356 5.68881 0 4.9999 0V2C5.76828 2.00006 6.46917 2.28899 6.9999 2.76411C7.12079 2.87224 7.23306 2.99012 7.33545 3.11712C7.36327 3.15163 7.39029 3.18672 7.4165 3.22234C7.72432 3.64014 7.92639 4.14063 7.98325 4.68433C8.00546 4.89423 8.00546 5.10595 7.98322 5.31595C7.92629 5.85984 7.72404 6.36049 7.41599 6.77835C7.40092 6.79882 7.38559 6.81911 7.36999 6.83921C7.21232 7.04239 7.02967 7.22382 6.82692 7.3795C6.32094 7.76863 5.68732 8 4.99966 8C4.55584 8 4.13454 7.90363 3.75552 7.73066Z"
      >
        <animateTransform
            attributeName="transform"
            attributeType="xml"
            dur=".8s"
            from="0 5 5"
            repeatCount="indefinite"
            to="360 5 5"
            type="rotate"
        />
      </path>
    </symbol>
  </svg>
</template>
